import React from "react";
import Panel from "../components/templates/panel";
import Ribbon from "../components/templates/ribbon";
import Purpose from "../components/templates/proposal";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Content from "../components/templates/content";

import Login from "../components/login";

const VisionPage = () => (
  <Layout>
    <Login />
    <SEO title="Our Vision" />
    <Panel imgLink={"/images/sign_off_banner.jpg"}>
      <div className="mobile-hide">
        <h1>We’d love to work together!</h1>
      </div>
    </Panel>
    <div className="mobile-show desktop-hide">
      <Ribbon bgColor="black">
        <h1>We’d love to work together!</h1>
      </Ribbon>
    </div>
    <Content>
      <div className="container">
        <h2>
          We hope this proposal has given you a good overview of our passion for
          places that inspire and transform the ways we think about them, our
          distinct ability to shape powerful stories that win hearts, and our
          proven track record.
          <br />
          <br />
          We think there&rsquo;s an exciting opportunity to inspire your
          audiences with a very different kind of story driven by clear purpose.
          One that shows Downtown West isn&rsquo;t about a campus, it&rsquo;s
          about creating the stage: so that San Jose can bring the performance.
          <br />
          <br />
          We&rsquo;d love to help you drive that narrative and connect with your
          audiences.<br></br>
          <br></br>dnco.com <br />
          @dnco<br></br> <br></br>If you have questions, please contact our
          team: <br />
          Joy <a href="mailto:jn@dnco.com ">jn@dnco.com </a>
          <br />
          Patrick <a href="mailto:pe@dnco.com ">pe@dnco.com </a>
          <br />
          Simon <a href="mailto:sy@dnco.com ">sy@dnco.com </a>
          <br />
          Zo&euml; <a href="mailto:zb@dnco.com ">zb@dnco.com </a>
          <br />
          Luis <a href="mailto:lm@dnco.com ">lm@dnco.com </a>
          <br />
          Sam <a href="mailto:sj@dnco.com ">sj@dnco.com</a>
        </h2>
      </div>
    </Content>
    <Purpose
      fwd={false}
      bck={true}
      linkBack={"studies"}
      titleBack={"Case Studies"}
    />
  </Layout>
);

export default VisionPage;
